@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;600&family=Rubik:wght@400;600&display=swap');
/*****************************************
                Carousal CSS 
 *****************************************/
 body{
  font-family: 'Heebo', sans-serif!important;
 }
 .container{
  color: black ;
 }
 .dropdown ul {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(127,137,161,.25);
  left: 14px;
  margin: 0;
  padding: 10px 0;
  position: absolute;
width: max-content;
  top: calc(100% + 10px);
  transition: .3s;
}
.dropdown{
  cursor: pointer;
}
.nav-item .dropdown-nav-link {
  font-size: 16px;
  color: gray;
  padding-left: 1em;
  padding-right: 1em;
  cursor: pointer ;
}
.rs-picker-toggle .rs-stack{
  text-align: left;
}
.rs-picker-toggle-value{
  color: black !important;
}
.currency-modal{
  cursor: pointer;
}
/*****************************************
                Navbar CSS 
 *****************************************/
/* body{
  font-family: acherusgrotesque-regular !important;
} */
header{
 
  background: #fff;
  z-index: 999;
  width: 100%;
}
.navbar-top{
  border-bottom: 1px solid #424242;
  align-items: center;
  padding: 0 15px;
  /* background: #313695; */
  
}
header .nav-item.nav-link{
  padding: 3px;
}
#navbar{
  justify-content: center; 
}
.navbar-top img{
  padding: 5px;
}
.navbar-top-right{
  /* font-family: sans-serif; */
  display: flex;
  margin-top: 15px;
  align-items: center;
  justify-content: end;
  /* color: white; */
  gap: 1em;
  margin-right: 1em;
}
.navbar-top-right .d-flex{
  gap: .5em;
font-weight: 700;
}
/* .navbar-sign-in{
  color: #E19D18;
} */
.navbar-top-right div{
  align-items: center;
}
.navbar-top-right h6{
  margin-bottom: auto;
  font-weight: normal;
}
.widget_price_filter .css-b62m3t-container{
  z-index:99;
}
.currency-div{
  background: #4f4f4f;
  padding: 10px;
  color: white;
  width: 250px;
  text-align: center;
}
.top-button-effect button{
  background-color: #0e3367 ;

  border: none;
  border-radius: 0px;
}
.top-button-effect button:hover{
  background-color:  #0e3367;
  color:white;
  border: none;
}
.currency-item{
  font-size: 1.2em;
}
.nav-link:hover{text-decoration: none;}
.top-button-effect button:active:focus{

}
.navbar-link{
  gap:1.8em;
 
}
.navbar-link .nav-item .nav-link{
  color: #313695;
  font-size: 16px;
  font-family: 'Heebo', sans-serif;
  font-weight: bold;
  
}
.navbar-link .nav-item :hover{
  color: #d39d00 !important;
}
.navbar-link .nav-item{
text-align: left;
}
/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
color: #124265;
font-size: 28px;
cursor: pointer;
display: none;
line-height: 0;
transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
.mobile-nav-toggle {
    display: block;
    margin-right: 10px;
}

.navbar ul {
    display: none;
}
}
.navbar-mobile .dropdown ul{
font-size:.8em;
}
.navbar-mobile {
position: fixed;
overflow: hidden;
top: 0;
right: 0;
left: 0;
bottom: 0;
background: rgba(10, 38, 58, 0.9);
transition: 0.3s;
z-index: 999;
}
.navbar-toggler{
border: none;
}
.navbar-toggler:hover:active:focus{
border: none;
}
.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}
.navbar-mobile ul li {
    padding: .6em;
    border-bottom: 1px solid #302f3270;
    /* background-image: linear-gradient(to left, #2a78d190,#2a78d145,#2a78d130, rgba(255,0,0,0)) !important; */
}
.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    text-align: center;
    left: 15px;
    height: fit-content;
    background-color: #fff;
    overflow-y: auto;
    font-size: 1.2em;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    color: #124265;
}

    .navbar-mobile a:hover,
    .navbar-mobile .active,
    .navbar-mobile li:hover > a {
        color: #2487ce;
        
    }

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}
.submenu-toggle {
  position: absolute;
  right: 0;
  padding: 1.2em;
  display:none;
  text-align: center;
  cursor: pointer;
  border-left: 1px solid #eee;
  -webkit-transform: translateY(-56px);
  -moz-transform: translateY(-56px);
  -ms-transform: translateY(-56px);
  -o-transform: translateY(-56px);
  transform: translateY(-56px);
}
.navbar-mobile .dropdown ul {
    position: static;
    /* display: none; */
    z-index: 99;
    opacity: 1;
    width: 100%;
    visibility: visible;
    /* background-image: linear-gradient(to left, #2a78d140,#2a78d115,#2a78d105, rgba(255,0,0,0)) !important; */
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

    .navbar-mobile .dropdown ul li {
        min-width: 200px;
    }

    .navbar-mobile .dropdown ul a {
        padding: 10px 20px;
    }

        .navbar-mobile .dropdown ul a i {
            font-size: 12px;
        }

        .navbar-mobile .dropdown ul a:hover,
        .navbar-mobile .dropdown ul .active:hover,
        .navbar-mobile .dropdown ul li:hover > a {
            color: #2487ce;
        }

.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

.navbar-mobile .dropdown.show ul {
    display: block;
}

/*****************************************
              Packages CSS 
*****************************************/

.section-title {
  margin: 3em auto 1em;
  padding: 0;
  position: relative;
  text-align: center;
}
.section-title h4 {
  color: #9b9a9a;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 4px;
  margin-bottom: 12px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.section-title h2 {
  color: #363d48;
  font-family: 'Lora', serif;
  /* font-size: 36px; */
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 0.9;
  margin-bottom: 22px;
  text-transform: uppercase;
}
.section-title h2 span {
  color: #d3b254;
}
.package-filter button{
  background-color: #cdfbf79c;
  font-weight: 700;
  color: gray;
}

.package-filter button img{
  border-radius: 50%;
}
.package-filter button{
  width: auto;
}
.center-vertically {
  
  align-items: center;
}
.package-filter button:hover{
background-color: #cdfbf79c;
  font-weight: 700;
  color: gray;

}
.activefilter{
background-color: #d39d00 !important;
color: white !important;
border-color: darkgoldenrod !important;
}
.hr-devider{
  background-color: rgb(93, 91, 91);
  height: 5px;
  border: none;
  border-radius: 3px;
}

.owl-nav{
display: none;
}
.card-sl {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-image img {
  max-height: 100%;
  max-width: 100%;
  height:13em;
  border-radius: 8px 8px 0px 0;
}

.card-action {
  position: relative;
  float: right;
  margin-top: -25px;
  margin-right: 20px;
  z-index: 2;
  color: #E26D5C;
  background: #fff;
  border-radius: 100%;
  padding: 15px;
  font-size: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}

.card-action:hover {
  color: #fff;
  background: #E26D5C;
  -webkit-animation: pulse 1.5s infinite;
}

.card-heading {
 
  font-weight: bold;
  background: #fff;
  padding: 10px 15px;
  min-height: 65px;
}

.card-text {
  padding: 6px 8px;
  background: #fff;
  font-size: 14px;
  color: #636262;
}

.card-button {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  text-decoration: none;
  background-color:#d39d00;
  color: #fff;
  border-radius: 0 0 8px 8px;
}

.card-button:hover {
  text-decoration: none;
  background-color: #1D3461;
  color: #d3b254;
  ;

}


@-webkit-keyframes pulse {
  0% {
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
  }

  70% {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }

  100% {
      -moz-transform: scale(0.9);
      -ms-transform: scale(0.9);
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
.moon-icon{
height: 15px;
}

/*****************************************
              Hotels CSS 
*****************************************/
.img-grid img{
width: 100%;
}
.column {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 0;
}

.column img {
 
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
.image-container {
  position: relative;
  overflow: hidden;
}
.overlay{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.369); /* Black transparent background */
  color: white; /* Text color */
  padding: 10px 20px;
  border-radius: 5px;
}
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Text color */
  padding: 10px 20px;
  border-radius: 5px;
}
.image-container:hover img {
  transform: scale(1.1); /* Zoom in the image on overlay hover */
}
.image-container img {
  transition: 0.3s;
  height:16em;
}

.image-container:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}
.overlay:hover{
  cursor: pointer;
}


/*****************************************
             MorePackages CSS 
*****************************************/
.grid-wrapper > div {
display: flex;
justify-content: center;
align-items: center;
}
.grid-wrapper > div > img {
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 5px;
}

.grid-wrapper {
display: grid;
grid-gap: 10px;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
grid-auto-rows: 200px;
grid-auto-flow: dense;
}
.grid-wrapper .wide {
grid-column: span 2;
  grid-row: span 1;
}
.grid-wrapper .tall {
grid-row: span 2;
  grid-column: span 2;
}
.grid-wrapper .big {
grid-column: span 2;
grid-row: span 2;
}

.morepackages-image-container .overlay{
  background-color: rgba(0, 0, 0, 0.201);
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}
.section-title h2 {
  position: relative;
}

.section-title h2::before,
.section-title h2::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 14%;
  height: 1px;
  background-color: #d3b254; /* Adjust the color as needed */
  transform: translateY(-50%);
}

.section-title h2::before {
  left: 0;
}

.section-title h2::after {
  right: 0;
}

/*****************************************
             Footer CSS 
*****************************************/
.footer-section {
  background-color: #06083024;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
/* .footer-section:before {
  content: '';
  position: absolute;
  top: -146%;
  left: -18%;
  width: 44%;
  height: 257%;
  transform: rotate(54deg);
  background-color: #060830eb;
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  z-index: -10;
}
.footer-section:after {
  position: absolute;
  content: '';
  background-color: #060830eb;
  top: -24%;
  right: 4%;
  width: 26%;
  height: 264%;
  transform: rotate(44deg);
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  -o-transform: rotate(44deg);
  z-index: -10;
} */
.footer-top {
  padding-top: 96px;
  padding-bottom: 50px;
}
.footer-top p,
.company-footer-contact-list li {
  color: gray;
}
.company-footer-contact-list {
  margin-top: 10px;
}
.company-footer-contact-list li {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.company-footer-contact-list li+li {
  margin-top: 5px;
}
.company-footer-contact-list li i {
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
}

.footer-top .site-logo {
    margin-bottom: 25px;
    display: block;
    width: 40%;
    margin-left: auto;
  margin-right: auto;
  }
.widget-title {
  text-transform: capitalize;
}
.footer-top .widget-title {
  color: #0e3367;
  margin-bottom: 40px;
}
.courses-link-list li+li {
  margin-top: 10px;
}
.courses-link-list li a {
  color: gray;
  text-transform: capitalize;
  /* font-family: var(--para-font); */
  font-weight: 400;
  text-decoration: none;
}
.courses-link-list li a i{
  color: #0e3367;
}
.courses-link-list li a:hover {
  color: #ffb606;
}
.courses-link-list li i {
  margin-right: 5px;
}
.footer-top .small-post-title a {
  /* font-family: var(--para-font); */
  color: #ffffff;
  font-weight: 400;
}
.small-post-item .post-date {
  color: #ffb606;
  margin-bottom: 3px;
  font-family: var(--para-font);
  font-weight: 400;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
max-width: 100%;

}
.small-post-list li+li {
  margin-top: 30px;
}
.news-letter-form {
  margin-top: 15px;
}
.news-letter-form input {
  width: 100%;
  padding: 12px 25px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: none;
}
.news-letter-form input[type="submit"] {
  width: auto;
  border: none;
  background-color: #ffb606;
  padding: 9px 30px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #ffffff;
  margin-top: 10px;
}
.footer-bottom {
  padding: 13px 0;
  border-top: 1px solid rgb(0 0 0 / 27%);
}
/* .copy-right-text {
  color: #ffffff;
} */
.copy-right-text a {
  color: #ffb606;
}
.terms-privacy li+li {
  margin-left: 30px;
}
.terms-privacy li a {
  color: #ffffff;
  position: relative;
}
.terms-privacy li a:after {
  position: absolute;
  content: '-';
  color: #ffffff;
  display: inline-block;
  top: 0;
  right: -18px;
}
.terms-privacy li+li a:after {
  display: none;
}

.web-color{
  color: #0e3367;
  font-weight: bold;

}
.footer-newsletter{
  /* font-family: "Oswald", sans-serif; */
  font-weight: 400;
  color: #0e3367;
  letter-spacing: 0.02em;
}
.social-icons {
  align-items: center;
  display: flex;
  justify-content: center;
}
/*****************************************
             Search CSS 
*****************************************/
.booking_part .booking_menu {
background-image: linear-gradient(to right, #000428 0%, #004e92  51%, #000428  100%) !important;

padding: 0 0;
}
.booking_part .booking_menu .nav-tabs {
border-bottom: 0px solid transparent;
}
.booking_part .booking_menu .nav-tabs .nav-item .nav-link, .booking_part .booking_menu .nav-tabs .nav-link {
color: white;
font-size: 16px;
border-color: transparent;
border-bottom: 3px solid transparent;
text-transform: uppercase;
padding: 11px 17px;
border-radius: 0;
border-right: 1px solid #d5aa2e47;
}

.booking_part .booking_content {
background-color: white;
box-shadow: 0px 10px 40px 0px rgba(221, 221, 221, 0.3);
padding: 20px;
}
.tab-content>.active {
display: block;
}
.overlay-container {
position: relative;
}
.booking_part .form-row {
display: flex;
justify-content: space-between;
}
.booking_part .form-row .form_colum {
width: 20%;
}
/* .check-availabilty {
margin-top: -170px;
position: relative; } */

  .block-32 {
    /* background: #fff; */
    border-radius: 23px;
}
.search-border{

position: relative;

}
.search-menu .nav-tabs {
position: relative;
border-radius: 0;
overflow: hidden;
background: none;
margin: 0;
border: 0;
z-index: 9;
}
.booking_part{
position: absolute;
top: 20%;
left: 0;
margin: 0 auto;
right: 0;
max-width: 80%;
padding-top: 0;
}
.booking_part .container{
position: relative;
z-index: 100;
}
.select-styling{
border-color: #313695;
border-radius: 5px;
}
.transfer-card-top{
  display: flex;
}
.field-icon-wrap {
position: relative;

}
.mobile-Filter-info ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #5a7adb;
  font-weight: 600
}
.mobile-Filter-info{
  display: none;
}
/* p+p {
 margin-top: 0px !important; 
} */
.checkout-note p {
line-height: 35px;
padding-left: 10px;
}
.select-styling:focus{
box-shadow: none !important ;

}
.form-control-feedback {
position: absolute;
left: 8px;
top: 50%;
color: #313695;
transform: translateY(-50%);
}
::placeholder{

color: #80808091 !important;

}
.fff-white{
  color: #fff;
}
.checkout-note {
  background-color: #313695;
  color: #fff;
}
.date-pading{
padding-left: 2em !important;
}
.card-price-section .form-control{
padding-left: 8px
}
.form-control {
/* padding-left: 30px; */
width: 100%;
/* height: 40px; */
border-color: #313695;
border-radius: 5px;
}
.margin-checkout .form-control{
  height: 50px;
  border: 1px dotted #313695;
}
.search-form-control{
padding-left: 30px;
}
.react-datepicker__input-container {
position: unset !important; 

}
.button-4{
  margin-top: 12em;
}
.filght-fa-rotate{
  rotate: 180deg;
}
.button-2{
  border: 1px solid #313695 !important;
}
.select-room1{
  border-radius: 5px ;
  width: 100%;
}
.mobile-Filter-info{
  display: none;
}
.search-btn1{
  background-image: linear-gradient(to right, #000428 0%, #004e92  51%, #000428  100%) !important;

  border-radius: 5px;
  width: 100%;
  color: #fff;
  border: none;
  border-radius: 999px;
  box-shadow: #313695 0 10px 20px -10px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
 
  word-break: break-word;
  border: 0;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.search-btn2{
background-color:#d39e00e2;
border-radius:5px;
}
.modal-btn1{
background-color:#d39e00e2 !important;
border-color: #d39d00;
}
.modal-content {
border-radius: unset !important;
}
.p-view-detail{
cursor: pointer;
}
.search-btn1:hover{
background-color:#d39e00e2;
border-color: #d39d00;
}
.search-btn2:hover{
background-color:#d39e00e2;
border-color: #d39d00;
}
.search-btn1:focus:active{
background-color:#d39e00e2;
}
.search-btn2:focus:active{
background-color:#d39e00e2;
}
.active-search{
background-color:#fff !important;
color: #313695 !important;
}
.active-search .nav-link{
  color: #313695 !important;
}
.booking_menu .nav-tabs .nav-item :hover{
cursor:pointer;
}
.delete-room-button{
color: red;
}
.number-wrapper:before {
content: "\25BC";
margin-bottom: 13px;
bottom: -0.5em;
}
.rs-picker-toggle{
text-align: center !important;
color: #000;
}
.rs-stack-item svg{
color:#313695 ;
}
.rs-picker-toggle-value{
color: #000;
font-weight: 500;
}
.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}
.field-icon-wrap:after, .field-icon-wrap:before {
position: absolute;
color: #e9c070;
right: 5px;
width: 1.6em;
height: 0.9em;
font-size: 10px;
/* pointer-events: none; */
background: #fff;
}
.tab-btn-right {
float: right;
padding: 0.3rem;
}
.tab-btn-right .btn {
background: #e19d183b;
border: 1px solid #e19d18;
color: #fff;
}
.tab-btn-right .btn-info:focus:active{
background: #e19d183b;
border: 1px solid #e19d18;
color: #fff;
}
.react-datepicker-wrapper{
width :100%;
}

.responsive-map{
overflow: hidden;
height: 30em;
}
.responsive-map iframe{
height:100%;
width:100%;
}
.nav-item .active{
 
 color: #d39d00 !important;
}
.currency-btn{
  background: #0e3367;
  border:none;
}
.currency-btn:hover{
  background: #0e3367;
  border-color: white;
}
.currency-btn:active:focus{
  background: transparent;
  border-color: white;
}
/*****************************************
             Contact Us CSS 
*****************************************/
.contact-img img{
  width: -webkit-fill-available;
}
.contact-img img{
  width: 100%;
  height: 200px;
    object-fit: cover;
}
.contact-img{
  position: relative;
text-align: center;
}
.contact-img h1{
  position: absolute;
  top: 50%;
  left: 50%;
  padding: .4em;
  border-radius: 5px;
  padding-right: .6em;
  color: #fff !important;
  padding-left: .6em;
  color: rgb(54, 61, 72);
  background-color: #001f4e;
  transform: translate(-50%, -50%);
}
.contact-img .filter-1{
  position: absolute;
  top: 50%;
  left: 50%;
  padding: .4em;
  border-radius: 5px;
  padding-right: .6em;
  padding-left: .6em;
  color: rgb(54, 61, 72);
  transform: translate(-50%, -50%);
}
textarea {
  overflow: auto;
  resize: vertical;
}
.bg-primary {
background-color: #001f4e !important;
}
.contact_border{
border-bottom:1.2px solid white;
}
.h4-contact {
font-size: 2em;
line-height: 25px;
color: white !important;
font-weight: 500;
text-align: left;
/* font-family: 'Times New Roman', Times, serif; */
}
.h4-contact-gap{
padding-top:1.2em;
}
.p-contact {
margin-bottom: 10px !important;
font-weight: 400 !important;
font-size: 14px !important;
color: white;
line-height: 24px !important;
text-align: left;
}
.strong_text{
font-size:1.2em;
color:#ffb606;
}
.mail:before {
content: "\2022";
color: #ffb606;
font-weight: 900;
display: inline-block;
width: 1.5em;
}
.label_text{
font-weight:600;
font-size:.9em;
padding:5px;
}
/*****************************************
             About Us CSS 
*****************************************/

.about-us-section h1 {
  color: #313695;
  text-transform: uppercase;
  font-size: 30px;
}
.about-us-section p {
font-size: 16px;
text-align: justify;
}
.about-4 .col-sm-6:nth-child(1) h3 {
color: #313695;
}
/*****************************************
            Umrah Packages CSS 
*****************************************/
.noUi-connect{
transform: translate(4.06767%, 0px) scale(0.959323, 1);
}
.noUi-origin{
transform: translate(-95.9323%, 0px); z-index: 5;
}
.modal-90w{
  min-width: 70%;
}
.p-card-title{
text-decoration: none;
color: #262626;
}
.p-card-title{
text-decoration: none;
color: #313695;
}
.package-filter .btn-success:focus:active{
background-color: #d39e00fd !important;
} 
.departure-date {
  font-size: 16px;
  color: #ffffff;
  border: 1px solid #000;
  display: inline-block;
  padding: 2px 15px;
  border-radius: 15px;
  background-color: #313695;
}
.time_length {
width: 100%;
font-size: 13px;
text-align: center;
}
.p-card-price h6{
  font-size: 1.5em;
  color: #313695;
  border: 1px solid #313695;
  
  border-radius: 16px;
  padding: 5px 0;
}
.flight-transfer-ad{
  background-repeat: no-repeat;
  background-color: #970f11;
  width: auto;
  border-radius: 4px;
  height: 9.5em;
}
.flight-transfer-ad img{
  margin-left: .5em;
  height: fit-content;
  width: 97%;
  /* margin-top: auto; */
}
.tour_length {
font-size: 16px;
font-weight: bold;
}
.p-view-detail{
color: #477fe7;
}
.f-13 {
font-size: 13px;
}
.p-car-departure p .fa-solid{
font-weight: bold;
  margin-bottom: 0;
}
.p-car-departure p {
margin-bottom: 0;
}
.parent_row {
box-shadow: 3px 6px 6px -4px #808080bd;
padding: 0.5rem 0px;
border: 1px solid #80808038;
border-radius: 7px;
}
.tour-img {
width: 100%;
/* height: 145px; */
}
.mapboxgl-map {
font: 12px/20px Helvetica Neue,Arial,Helvetica,sans-serif;
overflow: hidden;
position: relative;
-webkit-tap-highlight-color: rgb(0 0 0/0);
}
.mapboxgl-canary {
background-color: salmon;
}
.mapboxgl-ctrl-top-left {
top: 0;
left: 0;
}
.page-sidebar .widget {
border-bottom: 1px solid #DFDFDF;
margin-bottom: 15px;
}
.page-sidebar .widget h3 {
position: relative;
font-size: 14px;
font-weight: 700;
color: #313695;
margin-top: 0;
margin-bottom: 10px;
text-transform: uppercase;
}
.widget ul {
list-style: none;
padding: 0;
}
.widget_has_radio_checkbox ul li {
padding: 6px 0;
}
.widget_has_radio_checkbox ul li label {
position: relative;
display: block;
margin: 0;
-webkit-user-select: none;
-moz-user-select: none;
user-select: none;
}
.widget ul li > label {
font-size: 12px;
font-weight: 600;
}
.widget_has_radio_checkbox ul li label .rating {
font-size: 12px;
}
.page-sidebar .widget {
border-bottom: 1px solid #DFDFDF;
margin-bottom: 15px;
}
.tagcloud {
font-size: 0;
margin-left: -4px;
margin-right: -4px;
margin-bottom: 16px;
}
.tagcloud a {
display: inline-block;
font-weight: 600;
font-size: 12px !important;
color: #666;
padding: 3px 10px;
background-color: #ddd;
margin: 4px;
}

.noUi-target {
width: 97% !important;
}
.FontSize15{
  font-size: 15px;
  text-align: justify;
}
input[type=checkbox] {
accent-color: #d39d00;

}
.custom-textbox {
  position: relative;
  accent-color: #d39d00;
  border: 3px solid #000;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.3em .6em 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 19px;
  width: 19px;
  -webkit-appearance: none;
  opacity: 0.6;
}
.custom-textbox:checked {
  background-color: #313695;
  border: 2px solid #313695;
  opacity: 1;
}

.custom-textbox:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
.widget_has_radio_checkbox ul li label .rating {
font-size: 12px;
}
.widget_has_radio_checkbox ul li label .rating .fa {
font-size: 13px;
color: #B1B1B1;
}
.card-star .fa{
color: #ffd762;
}
.card-title a:hover{
color: #cb9f1c;
}
.card-price-section{
margin-top: auto;
margin-bottom: auto;
}
#map-container-google-2{
width: 100%;
}
#map-container-google-2 iframe{
width: inherit;
}
.modal-dialog {
overflow: inherit;
}


/*****************************************
           View Detail CSS 
*****************************************/

.image-lightbox {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
z-index: 999;
}

.image-lightbox img {
max-width: 90%;
max-height: 90%;
}
.middle {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
height: 100%;
width: 100%;
opacity: 0;
transition: .5s ease;
background-color: #00000089;
}
.text {
color: white;
font-size: 20px;
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
text-align: center;
}
.hellll:hover .middle {
opacity: 1;
cursor: pointer;
}
.set-detail-img{
height: 8em !important;
}
.set-detail-img img{
height: 100% !important;
width: 100% !important;
object-fit: cover !important;
}
.view-detail-img{
opacity: 1;
display: block;
width: 100%;
height: auto;
transition: .5s ease;
border-radius: 3px;
backface-visibility: hidden;
}
.search-icon-viewdetail{
font-weight: bold;
}

/* Navbar.css */
.view-detail-navbar {
position: sticky;
top: 0;
background: #313695;
color: white;
padding: 10px;
z-index: 100;
}

.view-detail-navbar ul {
list-style-type: none;
margin: 0;
padding: 0;
display: flex;
}

.view-detail-navbar a {
text-decoration: none;
display: block;
padding: 0.5rem 1rem;
color: white;
}
.tc {
color: #000 !important;
}
.item p {
font-size: 16px;
color: #000;
margin-bottom: 0;
text-overflow: ellipsis;
overflow: hidden;
white-space: nowrap;
}
.item {
padding: 13px 0px;}
.detail-view-btn{
  /* width: auto !important; */
  font-weight: 500;
}
.ticket-price {
  width: 100%;
}
.ticket-price thead{
border-bottom: 1px solid #0000006e;
}
.ticket-price .tr-border{
border-right: 1px dotted black;
border-left: 1px dotted black;

}
.table-border .amount{
font-size: 1.5em;
}
.ticket-size{
font-size:1.1em;
}
/* .accordion {
background-color: #eee;
color: #444;
cursor: pointer;
padding: 18px;
width: 100%;
border: none;
text-align: left;
outline: none;
font-size: 15px;
transition: 0.4s;
}

.active, .accordion:hover {
background-color: #ccc;
}

.accordion:after {
content: '\002B';
color: #777;
font-weight: bold;
float: right;
margin-left: 5px;
}

.active:after {
content: "\2212";
}

.panel {
padding: 0 18px;
background-color: white;
max-height: 0;
overflow: hidden;
transition: max-height 0.2s ease-out;
}
*/
.accordion,.accordion2 {
background-color: unset;
color: black;
/* color: #cb9f1c; */
cursor: pointer;
padding: 11px;
width: 100%;
border: none;
text-align: left;
outline: none;
font-weight: 500;
font-size: 16px;
border-bottom: 1px solid #80808066;
transition: 0.4s;
}

/* .active, .accordion:hover {
background-color: #ccc;
} */

.accordion:after,.accordion2:after {
content: '\002B';
/* color: #777; */
font-weight: bold;
float: right;
margin-left: 5px;
}

/* .active:after {
content: "\2212";
} */

.panel {
padding: 0 18px;
background-color: white;
max-height: 0;
overflow: hidden;
font-size: 14px;
transition: max-height 0.2s ease-out;
}
.panel p{
margin-top: revert;
text-align: justify;
}
.active-2{
color: #d39d00 !important;
}
.accordion:before {
  color: #313695;

content: "\27A2";


margin-left: -18px;

position: absolute;

}
/* section {
padding-top: 60px;
} */
.flight-detail-text{
font-size: 1.4rem;
  color: #06a803;

}
.flight-text-center{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.flight-heading{
  text-align: center;
  font-weight: 600;
  background: #313695;
  padding: 5px 52px;
  border-radius: 6px;
  font-size: 23px;
  color: #fff;

}
.package-total{
  text-align: end;
}
.view-detail-heading{
  
/* font-weight: 600; */
border-bottom: 1px solid gray;
/* border-top: 1px solid gray; */
padding: 5px;
/* border-radius: 6px; */
/* font-family: ui-serif !important; */
}
.feedback {
  padding: 10px 20px;
  border-radius: 4px;
}
.book-package-side-box{
  font-size: 16px ;
  color: #193555 ;
  margin-right: 10px ;
}
#mybutton {
  position: fixed;
  bottom: -4px;
  right: 16px;
  bottom: 1em;
  display: none;
}
.flight-heading2{
text-align: center;
font-weight: 600;
background: #000;
padding: 5px;
color: white;
border-radius: 6px;
/* font-family: ui-serif !important; */

}
.cs-bar_list {
margin: 0;
padding: 0;
list-style: none;
position: relative;
}


/*****************************************
           Flight Card CSS 
****************************************/

.fl-flight-container {

width: 100%;


position: relative;
overflow: hidden;
margin-bottom: 15px;

width: 100%;
}

.index-package-card{
cursor: pointer;
}
.index-package-card:hover{
  color:black;
  background-color: #d0a233;

}
.fl-flight-container .flight-name {

  color: #fff;
  font-size: 19px;
  font-weight: 600;
  
  background-color: #313695;
  padding: 5px 60px;
  display: inline-block;

}

.fl-detail-left {

background: #fff;

}

.fl-detail-left .fl-detail-left-container {
display: flex;
flex-flow: wrap;
width: 100%;
padding: 0 20px;
margin: auto;
/* border-right: 1px solid #e4e4e4; */
}
.fl-detail-left .fl-flight-schedual {
width: 100%;
/* display: flex; */
padding-top: 10px;
}
/* .hotel-detail-padding{
padding-top: 23em;
} */
.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail h4.left {
width: 33.33%;
}
.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail h4 {
font-size: 14px;
color: #0b0b0b;
font-weight: 600;
}
.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail h4.middle {
margin: 5px auto;
}

.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-direction {
width: 90%;
display: flex;
position: relative;
margin: auto auto 0px;
}
.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-direction .fl-route-bar {
width: 100%;
height: 2px;
border-bottom: 1px solid #f8c811;
}
.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-direction .fl-icon {
width: 35px;
margin: auto;
position: absolute;
left: 48%;
top: -8px;
/* background-color: #fff; */
z-index: 1;
text-align: center;
}
.fl-width{
width: 33%;
}

.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail p.left {
width: 33.33%;
text-align: left;
}
.fl-detail-left .fl-flight-schedual .fl-flight-route .fl-route-detail p {
width: 33.33%;
font-size: 14px;
/* color: #545353; */
font-weight: 700;
margin: 0;
}

.fl-detail-left .fl-flight-schedual .fl-flight-route {
display: flex;
flex-flow: wrap;
padding-bottom: 16px;
}
.fl-flight-schedual .fl-flight-route .fl-route-detail {
width: 100%;
display: flex;
margin-bottom: 10px;
}
.fl-flight-container:after {
content: '';
position: absolute;
bottom: -15px;
left: 50%;
width: 30px;
height: 30px;
border-radius: 50%;
background-image: linear-gradient(to top,#fff,#00000014);
z-index: 1;
}
.fl-route-bar .fl-icon img {
max-width: 100%;
vertical-align: middle;
border-style: none;
}
.item-thumb {
position: relative;
text-align: center;
}
.tr-vehicle-name span {
font-size: 12px;
font-weight: 400;
color: #6e6a6a;
}
.item-from{
display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
}
.item-time{
display: inline-block;
padding: 0 20px;
text-align: center;
vertical-align: middle;
}
.item-time .fa {
color: #06a803;
font-size: 50px;
display: block;
}
.item-time span {
display: inline-block;
font-weight: 600;
font-size: 14px;
color: #313695;
padding: 8px 6px;

}
ul li a.current {
background: black;
}
.page-top {
overflow: hidden;
margin-top: auto;
    margin-bottom: auto;

}
.page-top .awe-select-wrapper {
float: right;
}
.awe-select-wrapper {
position: relative;
display: inline-block;
}
.page-top .awe-select-wrapper .awe-select {
min-width: 170px;
line-height: 36px;
height: 36px;
border-color: #8080806e;
-webkit-border-radius: 2px;
-moz-border-radius: 2px;
-ms-border-radius: 2px;
-o-border-radius: 2px;
border-radius: 1px;
}
.page-top .awe-select-wrapper .awe-select:active:focus::selection {
border-color: #8080806e;
}
.awe-select-wrapper select {
border: 1px solid #d4d4d4;
background-color: #fff;
height: 34px;
line-height: 40px;
padding: 0 10px;
color: #666;
font-size: 16px;
box-shadow: none;
-webkit-border-radius: 1px;
-moz-border-radius: 1px;
-ms-border-radius: 1px;
-o-border-radius: 1px;
border-radius: 1px;
}
.parkagecartmodel{
max-width: 90%;
}
.umrah-package-modal-checkbox{
background-color: #313695 !important;
}
.umrah-package-modal-checkbox2{
  background-color: #2a7c2a!important;
  color: white !important;
  }
.checkbox-alias{

display: inline-block;
width: 100%;
height: auto;
z-index: 1;
position: relative;
transition: all 250ms ease-out;
cursor: pointer;
color: #000;
text-align: center;
font-size: 20px;
padding: 15px 10px;
}
.text-overimg{
position: relative;
text-align: center;
color: black;
}
.package-optional{
cursor: pointer;
}
.package-optional:hover{
cursor: pointer;
color:#d39e00e2
}
.css-13cymwt-control{
  border-color:#313695 !important;
}
.price-start{
  color: white;
    padding: .6em;
    background: #438f1d;
    border-radius: 4px;
}
.passport-preview{
display: flex;
flex-direction: column;
text-align: center;
}
.text-over-img{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
.set-tour-filter{
padding: 2em;
border-radius: unset;
}
.flight-filter12{
  padding: 1em;
  border-radius: 5px;
  cursor: pointer;
  background: #80808014;
  border-left: 1px solid #80808033;
}
.flight-filter-color{
  color:green;
}
.flight-filter-color .d-flex .flight-filter-color{
  color:white !important;
}
.package-invoice23{
  padding: .5em;
    display: flex;
    justify-content: space-between;
    background: #8080801f;
}
.packg-inv-hotel{
  padding: .3em;
    background: #80808033;
}
.flight-filter12:hover{
  border-bottom: 2px solid #3498ff;

}
.filter-logo-color{
  color: #3498ff;
}
.flight-filter12-active{
  color: white;
  background: #07193a;

}
.box-top{
border: 1px solid #c9c9c9;
margin-top:15px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.border01{
border-right: 1px dotted;
}
.filter-1{
width: 100%;
text-align: left;
}
.item-address {
color: #000;
}
.card-holder-name{
    padding-top: 0.17em;
    padding-bottom: .17em;
}
.hotel-top{
  background-color: #80808024;
    padding: 1em;
    border-radius: 5px;
}
.hotel-card-button{
background-color: #d39e00e2;
  border-radius: 5px;
  border: none;
  font-size: x-small;
}
.hotel-card-button:active:focus{
background-color: #d39e00e2;
}
.hotel-card-button:hover{
background-color: #d39e00e2;
}
.room-type-table {
width: 100%;
min-width: 650px;
}
.hotel-detail-border{
  border-bottom: 1px dotted #3136;
/* border-top: 2px solid #d39e00e2; */
}

#reviews .rating-info {
overflow: hidden;
margin-bottom: 1em;
}
#reviews .rating-info .average-rating-review {
display: inline-block;
overflow: hidden;
min-width: 290px;
float:left;
}
#reviews .rating-info .average-rating-review.good .count {
background-color: #55A649;
}
#reviews .rating-info .average-rating-review .count {
display: inline-block;
width: 70px;
height: 70px;
border-radius: 4px;
line-height: 70px;
text-align: center;
font-size: 32px;
font-weight: 700;
color: #fff;
float: left;
margin-right: 30px;
}
#reviews .rating-info .average-rating-review em {
display: block;
font-size: 12px;
font-weight: 700;
font-style: normal;
color: #d38e17;
margin-top: 15px;
}
#reviews .rating-info .average-rating-review span {
display: block;
font-size: 16px;
color: #666;
}
#reviews .rating-info .rating-review {
display: inline-block;
list-style: none;
padding: 0;
margin: 0;
margin-top: 15px;
}
#reviews .rating-info .rating-review li {
display: inline-block;
padding: 0 20px;
}
#reviews .rating-info .rating-review li em {
display: block;
font-weight: 700;
font-size: 12px;
font-style: normal;
color: #d38e17;
}
#reviews #comments .commentlist {
list-style: none;
margin: 0;
padding: 0;
}
#reviews #comments .commentlist li .comment-box {
padding-left:2em;
background-color: #fff;
margin-top: 10px;
}

#comments .commentlist .comment-body {
position: relative;
overflow: hidden;
font-size: 13px;
color: #666;
}
#reviews #comments .commentlist li .comment-box .comment-body .meta .time {
font-size: 12px;
color: #A5A5A5;
float: right;
}
#reviews #comments .commentlist li .comment-box .comment-body .meta strong {
font-size: 16px;
font-weight: 700;
color: #666;
}
.text-justify{
text-align: justify;
}
#reviews #comments .commentlist li .comment-box .comment-body .description {
font-size: 14px;
color: #A6A6A6;
}
.rs-picker-toggle{
border-color:#313695 !important;
}

.d723d73d5f {
display: block;
font-family: var(--bui_font_emphasized_2_font-family);
font-size: var(--bui_font_emphasized_2_font-size);
font-weight: var(--bui_font_emphasized_2_font-weight);
line-height: var(--bui_font_emphasized_2_line-height);
margin: 0 var(--bui_spacing_1x);
min-width: 3em;
text-align: center;
}
.fff{
align-items: center;
border: var(--bui_border_width_100) solid var(--bui_color_border);
border-radius: var(--bui_border_radius_100);
display: flex;
height: 2.4em;
overflow: hidden;
border-radius: 4px;
border: 1px solid gray;
}

.adult-modal-btn {
outline: none;
border: none;
padding: 1rem 1rem;
font-size: 1.5rem;
font-size: 13px;
}
/* input {

text-align: center;
} */
.select-child{
align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: .5em;
}
.adult-modal-btn{
cursor: pointer;
background-color: unset;
color: #313695;

}

.child-age-select{
border-color: #bf920c;
margin-top: 6px;
}

.adult-modal-btn:hover {
background-color: #013a3a24;
}
loading {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(255, 255, 255, 0.9); /* White background with opacity */
display: flex;
justify-content: center;
align-items: center;
z-index: 9999; /* Ensure it's on top of other content */
}

.spinner {
border: 4px solid rgba(0, 0, 0, 0.3); /* Adjust border color as needed */
border-top: 4px solid #3498db; /* Adjust spinner color as needed */
border-radius: 50%;
width: 50px;
height: 50px;
animation: spin 1s linear infinite;
}
#loading-wrapper {
position: fixed;
width: 100%;
background: white;
height: 100%;
left: 0;
top: 0;
z-index: 999;
}

.lds-ripple {
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.lds-ripple div {
position: absolute;
border: 4px solid #fff;
opacity: 1;
border-radius: 50%;
animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
animation-delay: -0.5s;
}
@keyframes lds-ripple {
0% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 0;
}
4.9% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 0;
}
5% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 1;
}
100% {
  top: 0px;
  left: 0px;
  width: 72px;
  height: 72px;
  opacity: 0;
}
}

#loading-text {
display: block;
position: absolute;
top: 38%;
left: 38%;
/* width: 100px;
height: 30px; */
width: 20em;
height: auto;
margin: -7px 0 0 -45px;
text-align: center;
font-family: 'PT Sans Narrow', sans-serif;
font-size: 20px;
}

#loading-content {
display: block;
position: relative;
left: 50%;
top: 50%;
width: 170px;
height: 170px;
margin: -85px 0 0 -85px;
border: 3px solid #F00;
}
.booking-option-wrapper .expert-wrapper .expert-image {
  background-image: url('../Images/Home/phoneicon.png');
  width: 92px;
  height: 92px;
  background-size: contain;
  border: 3px solid #b3d4f7;
  border-radius: 50%;
  position: relative;
}
.booking-option-wrapper .expert-wrapper .expert-availability {
  padding: 2px 8px;
  border-radius: 20px;
  background-color: #e6f3e6;
  background-size: cover;
  font-family: Arial;
  font-size: 12px;
  color: #008a04;
  margin-top: 15px;
  line-height: 1;
}
.booking-option-wrapper .booking-option-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 22px;
  font-family: Arial;
  color: #313541;
}
.booking-option-wrapper .expert-wrapper .expert-image:after {
  content: "";
  position: absolute;
  bottom: -2px;
  right: 12px;
  width: 15px;
  height: 15px;
  background: #4dad4f;
  border-radius: 50%;
}
.booking-option-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.booking-option-wrapper .booking-option-details .booking-option-title {
  font-size: 24px;
  color: #313541;
  margin-bottom: 8px;
  font-weight: 600;
}
.booking-option-wrapper .booking-option-details .booking-option-subtitle {
  font-size: 13px;
  color: #313541;
  line-height: 24px;
  margin-bottom: 8px;
}
.booking-option-wrapper .booking-option-details .booking-mobile {
  margin-top: 6px;
  font-size: 24px;
  color: #313541;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
}
.popup-benefits {
  color: #3a465f;
  text-align: left;
  background: #ebecef;
  padding-bottom: 10px;
  border-radius: 0 0 8px 8px;
}
.popup-benefits .wHeding {
  text-align: center;
  padding: 1rem 1rem 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}
.popup-benefits h3 {
  font-size: .875rem!important;
}
.popup-benefits h2, .popup-benefits h3, .popup-benefits h4 {
  color: #3a465f;
  line-height: 1.25rem;
}
.popup-benefits .st1 {
  fill: #006ee3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  color:#006ee3
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0 auto;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  outline: none;
  max-width: 498px;
  transform: translateY(-50%);
}
.font-weight-normal {
  font-weight: 400!important;
}
.booking-option-wrapper .booking-option-details .booking-mobile a {
  color: #006ee3;
  text-decoration: none;
}
.activity_booking-Box{
  cursor: pointer;
  color: #1675e0;
}
/* .tour_details_boxed {
  background: #FFFFFF;
  box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 30px;
} */
.tour_details_boxed {
  background: #FFFFFF;
  box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 30px;
}
#loading-content:after {
content: "";
position: absolute;
border: 3px solid #0F0;
left: 15px;
right: 15px;
top: 15px;
bottom: 15px;
}

#loading-content:before {
content: "";
position: absolute;
border: 3px solid #00F;
left: 5px;
right: 5px;
top: 5px;
bottom: 5px;
}

#loading-content {
border: 3px solid transparent;
border-top-color: #4D658D;
border-bottom-color: #4D658D;
border-radius: 50%;
-webkit-animation: loader 2s linear infinite;
-moz-animation: loader 2s linear infinite;
-o-animation: loader 2s linear infinite;
animation: loader 2s linear infinite;
}

#loading-content:before {
border: 3px solid transparent;
border-top-color: #D4CC6A;
border-bottom-color: #D4CC6A;
border-radius: 50%;
-webkit-animation: loader 3s linear infinite;
  -moz-animation: loader 2s linear infinite;
-o-animation: loader 2s linear infinite;
animation: loader 3s linear infinite;
}

#loading-content:after {
border: 3px solid transparent;
border-top-color: #84417C;
border-bottom-color: #84417C;
border-radius: 50%;
-webkit-animation: loader 1.5s linear infinite;
animation: loader 1.5s linear infinite;
  -moz-animation: loader 2s linear infinite;
-o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
0% {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
}

@keyframes loader {
0% {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
}

#content-wrapper {
color: #FFF;
position: fixed;
left: 0;
top: 20px;
width: 100%;
height: 100%;
}

#header
{
width: 800px;
margin: 0 auto;
text-align: center;
height: 100px;
background-color: #666;
}
.booking-option-wrapper .modal-close-icon {
  position: absolute;
  top: 0.813rem;
  right: 0.813rem;
  cursor: pointer;
}
#content
{
width: 800px;
height: 1000px;
margin: 0 auto;
text-align: center;
background-color: #888;
}
.single-feature-icon {
width: 30px;
height: 30px;
line-height: 30px;
color: #313695;
font-size: 15px;
-webkit-transition: all .3s;
-moz-transition: all .3s;
-ms-transition: all .3s;
-o-transition: all .3s;
transition: all .3s;
text-align: center;
border-radius: 50%;
margin-right: 10px;
}
.single-feature-titles .title{
font-size: 11px;
}
.hotel-dec{
background: #FFFFFF;
  box-shadow: -4px -5px 14px rgb(0 0 0 / 8%), 5px 8px 16px rgb(0 0 0 / 8%);
}
.filter-show-hide{
  display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.hotel-dec h3{
border-bottom: 1px solid #bf920c; 
display: inline-block;
}
.book-now{
/* margin-top: 90%; */
overflow: visible;
box-sizing: border-box;
position: -webkit-sticky;
position: sticky;
}

.book-package-3 li{
  padding:.8em 20px .8em 20px;
}
.book-package-2{
  background-color: #ddd;
  margin-top: 4em;
}
.book-package-2 h6{
  padding: 1em 1em 0;
}
.book-package-4{
  padding: 1.5em;
  color: white;
  background-image: url(../Images//Pages/image12.PNG);
}
.theiaStickySidebar {
/* margin-top: 90%; */
position: sticky;
  top: 0;
  padding-top: 10px;
  /* z-index: 100; */
}
.select-room--checkbox {
border-radius: 4px;
background-color: #313695;
 color:white; 

}
.room-check{
margin-right: .4em;
}
.select-room--checkbox:hover{
border-radius: 4px;
background-color: #c5c6c7;
}
.tour_booking_amount_area ul li {
display: flex;
justify-content: space-between;
padding-bottom: 6px;
font-weight: 500;
font-size: 16px;
}
.border-line{
border-top: 1px solid #80808087;
}

.checkout-hotel-detail,.hotel-checkout-shadow{
box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.rs-anim-fade.rs-anim-in{
z-index: 9999;
}

/* ********************************
Booking Confirmation
*********************************  */
.title-vochure{
margin: 4px;
}
.invoice-top-img img{
height: 4.5em !important;
}
.title-vochure h2 {
margin: 0;
padding: 0;
color: #ae8c32;
}
.title-vochure p {
margin: 0;
color: #fff;
font-size: 24px;
}
.v-heading-icon img {
max-width: 75px;
padding: 0 15px;
}
.v-heading-icon-title h3 {
border-bottom: 2px solid #ae8c32;
color: #ae8c32  !important;
}
.float-left{
float: left !important;
}
.list-items-3 li {
justify-content: start;
word-break: break-word;
}
.list-items-3 li {
justify-content: start;
word-break: break-word;
margin: .6em;
}
.list-items-3 li {
display: -webkit-flex;
display: -ms-flex;
display: flex;
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: justify;
/* justify-content: space-between; */
}
.v-section-info {
padding: 8px ;
}
.list-items-3 li span {
width: 40%;
/* width: 250px; */
}
.invoice-heading{
border-radius: unset !important;
}
.invoice-lead-gest h6{
font-size: 1em;
}
a:hover{
text-decoration: none !important;
color: unset !important;
}
.cs-container {
max-width: 1000px;
padding: 30px 15px;
margin-left: auto;
margin-right: auto;
}
.cs-invoice.cs-style1 {
background: #fff;
border-radius: 10px;
padding: 50px;
}
.text-style01{
color: #cead65;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: bold;
}
.text-style02{
color: #000;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: bold;
}
.package_inv_hr{
border-bottom: 1px solid rgba(128, 128, 128, 0.461);
width: 99%;
}
.button-tabs {
display: flex;
}

.tab-button {
background-color: #f0f0f0;
border: 1px solid #cccccc03;
/* border-radius: 4px; */
padding: 10px 20px;
/* margin-right: 5px; */
cursor: pointer;
}

.tab-button.active {
background-color: white;
color: black;
border-top-color: #80808045;
border-right-color: #80808045;
border-left-color: #80808045;
border-bottom: none;


}
.flight-search label{
font-weight: bold;
}
.inv-btn button{
border-radius: 0;
font-weight: bold;
}
.image-cover_airline{
  transform: scale(0.15) !important;
  margin-left: -257% !important;
}
.image-cover_airline2{
  transform: scale(0.35) !important;
  margin-left: -156% !important;
}
.container3 {
display: flex;
justify-content: space-between;
align-items: center;
background: #8d891038;
padding: .3em;
}

.h4-line{
line-height: unset;
}
.logo-margin{
margin-left: -195% !important;
}
.flight-checkout-logo{
margin-left: 50%;
}
.set-page-ntm-width{
width: unset !important;
}
.phone-only {
  position: fixed;
  width: inherit;
  padding: 20px 10px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.25);
  font-size: 1.2rem;
  color: #313541;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,-20%);
  border-radius: 5px;
  min-width: 41em;
  z-index: 9;
}
.phone-only .phone-only-img {
  background: none;
  width: 90px;
  height: 65px;
  float: left;
  display: inherit;
  position: relative;
  top: -0.3rem;
}
.phone-only .phonelink {
  text-decoration: none;
}
.phone-only .cross-icon {
  position: absolute;
  cursor: pointer;
  font-weight: 700;
  color: #73767f;
  right: -2px;
  top: -15px;
  font-size: 1.4rem;
}
.about-text ul li {
  font-size: 16px;
  color: #333;
  position: relative;
  width: 50%;
  float: left;
  padding: 6px 0;
}
.about-text p{
  font-size: 18px;
  padding: 12px 0;
}
.featuresBox-wrap .featuresBox-icons{
  width: 80px;
  height: 80px;
  background: #0e3367;
  font-size: 40px;
  color: #fff;
  margin: 0 auto 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
} 
.featuresBox-wrap .featuresBox-icons::before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  border: 2px dashed #313695;
}
.featuresBox-captions{
  text-align: center;
}
.site-heading {
  margin-bottom: 50px;
  position: relative;
  text-align: center!important;
}
.site-title-tagline {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  background: #313695;
  border-radius: 50px;
  padding: 5px 20px;
}
.site-title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 40px;
  color: #0e3367;
  margin-top: 8px;
  margin-bottom: 0;
}
.destination-item {
  margin-bottom: 25px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}
.destination-info {
  position: absolute;
  bottom: 20px;
  left: 15px;
  transition: all .3s ease-in-out;
  z-index: 1;
}
.destination-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  border-radius: 15px;
  transition: all .3s;
  background: rgba(13,35,62,.5);
}
.destination-info h4 {
  color: #fff;
  font-size: 22px;
}
.download-img {
  text-align: center;
  position: relative;
  z-index: 1;
}
.download-img::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 500px;
  height: 500px;
  background: #f1f0ff;
  border-radius: 50%;
  z-index: -1;
  left: 0;
}
.site-title-tagline {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  background: #0e3367;
  border-radius: 50px;
  padding: 5px 20px;
}
.download-img img {
  width: 63%;
}
#section-1{
  background: #ddd;
  padding: 15px;
}
#section-1 .inc{
  background: #313695;
    color: #fff;
    padding: 6px 15px;
    font-size: 18px;
}
.pt-80 {
  padding-top: 80px;
}
.item-from .item-address{
  color: white;
    background: #313695;
    padding: 2px 15px;
}
.parent_row .card-price-section{
  border-left: 1px solid #313695;
}
.noUi-connect {
  background: #313695 !important;
}
.hotel-detail-border h5{
  text-transform: uppercase;
}
.package-img-top{
  border: 3px solid #ffd762;
}
.invisible-checkboxes{
  border-right: 1px solid #313695;
}
.box-top .form-control{
      height: 50px;
}

.app-container {
  display: flex;
  justify-content: space-between;
  transition: margin-right 0.3s ease-in-out; /* Add transition for smooth animation */
}

.pagination {
  justify-content: end;
  margin-bottom: 0;
}
.right-sidebar {
  width: 50%;
  background-color: #fff;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  right: -50%; /* Initial position outside the viewport */
  height: 100%;
  padding: 20px;
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
  animation: slideIn 0.3s ease-in-out forwards; /* Slide in animation */
}
.mobile-Filter-info{
  display: none;
}
.mobile-Filter-info ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #5a7adb;
  font-weight: 600
}
.flight-detail-sidebar{
  flex: 1 1 0px;
    background: rgb(255, 255, 255);
    border: 0.5px solid rgb(216, 220, 222);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16px;
    margin-right: 16px;
    font-weight: 400;
    margin-top: 1em;
    font-size: 12px;
    line-height: 16px;
}

.sidebar-open .right-sidebar {
  right: 0;
  animation: slideOut 0.3s ease-in-out forwards; /* Slide out animation */
}

.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 998; /* Below the sidebar but above the main content */
  
  display: none; /* Initially hidden */
}
.app-container.sidebar-open2 .overlay2 {
  display: block; /* Show overlay when sidebar is open */
}
.close-button {
  background: none;
  border: none;
  font-size: 1.3em;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #333;
}
@keyframes slideIn {
  from {
    right: -50%;
  }
  to {
    right: 0;
  }
}

@keyframes slideOut {
  from {
    right: 0;
  }
  to {
    right: -50%;
  }
}

.baggage-info{
  display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 64px 0px;
}

.payment-loader{
  text-align: center;
  margin: 5em;
}